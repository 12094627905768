<style scoped>
  .yue-con{
    padding: 12px 20px;
    line-height: 24px;
  }
  .yue-con p{
    margin-bottom: 10px;
  }
  .pagebgimg{
      position: fixed;
      top: 0;
      z-index: -1;
      width: 100%;height: 100%;
    }
    .pagebgimg>img{
      width: 100%;height: 100%;
    }
</style>
<template>
  <div class="create-con">
    <div class="yue-con" v-if="appShow">
      <h3 class="center" style="color: #000; font-size: 1.1rem; font-weight:600;margin: 5px 0 10px;">《{{agreementTitle}}》</h3>
      <div v-html="agreementCon"></div>
    </div>
    <div class="dialog-bg" v-if="dialogAppShow">
      <div class="dialog-box" style="padding-top: 0;width: 300px;">
        <div class="dialog-top"><img src="../../assets/image/yue01.png" width="100%"></div>
        <div class="dialog-con" style="padding: 15px 20px;">
          <div style="max-height: 200px; overflow: auto;">
            <h3 class="center" style="color: #000; font-size: 1.1rem; font-weight:600;margin: 0px 0 10px;">《{{agreementTitle}}》</h3>
            <div v-html="agreementCon"></div>
          </div>
        </div>
        <div class="dialog-btn">
          <button class="btn-cancel" @click="noAgree">不同意，退出</button>
          <button class="btn-sure" @click="agree">同意</button>
        </div>
      </div>
    </div>
    <div class="pagebgimg">
          <img :src="pagebg" alt="">
    </div>
  </div>
</template>
<script>
var ua = navigator.userAgent.toLowerCase()
function setupWebViewJavascriptBridge (callback) {
  /* eslint-disable */
  if (window.WebViewJavascriptBridge) { return callback(WebViewJavascriptBridge) }
  if (window.WVJBCallbacks) { return window.WVJBCallbacks.push(callback)}
  window.WVJBCallbacks = [callback]
  var WVJBIframe = document.createElement('iframe')
  WVJBIframe.style.display = 'none'
  WVJBIframe.src = 'wvjbscheme://__BRIDGE_LOADED__'
  document.documentElement.appendChild(WVJBIframe)
  setTimeout(function () { document.documentElement.removeChild(WVJBIframe) }, 0)
}
export default {
  computed: {
  },
  components: {},
  data () {
    return {
      appShow: false,
      dialogAppShow: false,
      agreementCon: '',
      agreementTitle: '',
      windowHeight: document.documentElement.clientHeight,
      pagebg: require('../../assets/image/pagebgsm.png')
    }
  },
  created() {
    // 适配背景图片
    if(this.windowHeight > 736) {
      this.pagebg = require('../../assets/image/pagebgbig.png')
    }
  },
  mounted () {
    if (localStorage.getItem('lwtId')) {
      this.appShow = true
      this.axios({
        method: 'get',
        url: '/v1/ugcCreateClaim',
        params: {
          lwtId: localStorage.getItem('lwtId')
        }
      }).then((res) => {
        if (res.data.ugcCreateClaim) {
          // alert(res.data)
          this.agreementCon = res.data.ugcCreateClaim
          this.agreementTitle = res.data.worldName
        } else {
          this.$toast(res.data.head.desc)
        }
      })
      this.axios({
        method: 'get',
        url: '/v1/getAgreeLog',
        params: {
          lwtId: localStorage.getItem('lwtId')
        }
      }).then((res) => {
        if (res.data.content.status === 0) {
          this.dialogAppShow = true
        } else {
          this.dialogAppShow = false
        }
      })
    }
  },
  methods: {
    noAgree () {
      if (ua.match(/zkdreamreading/i) == 'zkdreamreading') {
        setupWebViewJavascriptBridge(function (bridge) {
          bridge.callHandler('CloseWindow_IOS', {}, function(response) {
          })
        })
      } else {
        android.closeWindow();
      }
      this.dialogAppShow = false
    },
    agree () {
      this.axios({
        method: 'get',
        url: '/v1/addAgreeLog',
        params: {
          lwtId: localStorage.getItem('lwtId')
        }
      }).then((res) => {
        this.dialogAppShow = false
      })
    }
  }
}
</script>
